#canvas {
  background: #fff;
  position: absolute;
}

body {
  margin: 0;
  font-family: Courier;
}

.title {
  font-size: 45px;
}

.initialFont {
  font-family: sans-serif;
  font-size: 15px;
}

h2, h6 {
  text-shadow: 4px 4px 4px #aaa;
}

.centerWithMargin {
  margin: 0 auto;
}

.fontSizeSmall {
  font-size: small;
}

.marginAuto {
  margin: 0 auto;
}

.container {
  height: 100vh;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.particlesstyle {
  opacity: .05;
  z-index: 0;
}

.centered-row {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.leftAlignedRow {
  flex-direction: row;
  justify-content: left;
  display: flex;
}

.spaceEvenlyRow {
  flex-direction: row;
  justify-content: space-evenly;
  display: flex;
}

.col {
  flex-direction: column;
  justify-content: start;
  display: flex;
}

.centeredCol {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.createEventContainer {
  height: 450px;
  max-width: 500px;
  min-width: 300px;
  width: 100%;
  z-index: 2;
  background-color: #fff;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  box-shadow: 0 3px 8px #0000003d;
}

.scanTicketContainer {
  max-width: 500px;
  min-width: 300px;
  width: 100%;
  z-index: 2;
  background-color: #fff;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 30px;
  padding-top: 30px;
  display: flex;
  box-shadow: 0 3px 8px #0000003d;
}

.createEventTitle {
  text-align: center;
  color: gray;
  text-shadow: 4px 4px 4px #aaa;
  margin-bottom: 2px;
}

.eventDetailsInputTable {
  margin: 0 auto;
}

.createButton {
  width: 100px;
  text-align: center;
  margin-top: 25px;
  box-shadow: 0 54px 55px #00000040, 0 -12px 30px #0000001f, 0 4px 6px #0000001f, 0 12px 13px #0000002b, 0 -3px 5px #00000017;
}

.inputHelperText {
  font-size: 20px;
}

.tableLabel {
  color: gray;
  text-shadow: 4px 4px 4px #aaa;
}

.arginput {
  width: 200px;
  height: 30px;
  border: 2px solid #add8e6;
  border-radius: 4px;
}

.arginput:focus {
  border: 2px solid gray;
  box-shadow: 0 0 5px gray;
}

.arginput:disabled {
  border: 2px solid #fff;
  box-shadow: 0 0 5px #fff;
}

.labelTd {
  width: 50px;
}

.height50 {
  height: 120px;
}

.height200 {
  height: 200px;
}

.inputImage {
  margin-left: 10px;
}

.hide {
  display: none;
}

#welcomeMessage {
  z-index: 10;
  font-size: 35px;
}

.width-50 {
  width: 50px;
}

.width-300 {
  width: 300px;
}

.textAlign-center {
  text-align: center;
}

.button-56 {
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  text-shadow: 4px 4px 4px #aaa;
  height: 48px;
  max-width: 100%;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  background-color: #fff;
  border: 2px solid #d3d3d3;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 0 25px;
  font-family: Times New Roman, serif;
  font-size: 40px;
  line-height: 24px;
  text-decoration: none;
  display: flex;
  position: relative;
  box-shadow: inset 0 -23px 25px #0000002b, inset 0 -36px 30px #00000026, inset 0 -79px 40px #0000001a, 0 2px 1px #0000000f, 0 4px 2px #00000017, 0 8px 4px #00000017, 0 16px 8px #00000017, 0 32px 16px #00000017;
}

.button-56:after {
  content: "";
  height: 48px;
  width: 100%;
  z-index: -1;
  background-color: #111;
  border-radius: 8px;
  transition: transform .2s ease-out;
  display: block;
  position: absolute;
  top: -2px;
  left: 0;
  transform: translate(8px, 8px);
}

.button-56:hover:after {
  transform: translate(0);
}

.button-56:active {
  background-color: #ffdeda;
  outline: 0;
}

.button-56:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-56 {
    padding: 0 40px;
  }
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  color: #fff;
  text-align: center;
  z-index: 1;
  background-color: #333;
  border-radius: 2px;
  margin: auto;
  padding: 16px;
  font-family: matrix-code;
  font-size: medium;
  position: fixed;
  bottom: 30px;
}

#snackbar.show {
  visibility: visible;
  animation: .5s fadein, .5s 2.5s fadeout;
}

@keyframes fadein {
  from {
    opacity: 0;
    bottom: 0;
  }

  to {
    opacity: 1;
    bottom: 30px;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
    bottom: 30px;
  }

  to {
    opacity: 0;
    bottom: 0;
  }
}

.tooltip {
  cursor: pointer;
  border-bottom: 1px dotted #000;
  display: inline-block;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  z-index: 1;
  background-color: #000;
  border-radius: 6px;
  padding: 5px 0;
  font-family: sans-serif;
  font-size: small;
  position: absolute;
  left: -100px;
}

.tooltip .tooltiptextCenter {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  z-index: 1;
  background-color: #000;
  border-radius: 6px;
  padding: 5px 0;
  font-family: sans-serif;
  font-size: small;
  position: absolute;
  right: 100px;
}

.tooltip:hover .tooltiptext, .tooltip:hover .tooltiptextCenter {
  visibility: visible;
}

#eventCreator {
  height: 20px;
}

.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}

.lds-circle > div {
  width: 64px;
  height: 64px;
  background: #add8e6;
  border-radius: 50%;
  margin: 8px;
  animation: 2.4s cubic-bezier(0, .2, .8, 1) infinite lds-circle;
  display: inline-block;
}

@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(.5, 0, 1, .5);
  }

  0% {
    transform: rotateY(0);
  }

  50% {
    animation-timing-function: cubic-bezier(0, .5, .5, 1);
    transform: rotateY(1800deg);
  }

  100% {
    transform: rotateY(3600deg);
  }
}

.eventNameBackground {
  background-color: #bbdcf7;
  padding: 5px;
}

.button-53 {
  box-sizing: border-box;
  color: #000;
  text-align: center;
  width: 90%;
  max-width: 460px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  background-color: #bbdcf7;
  border: 0 solid #e5e7eb;
  justify-content: center;
  padding: .75rem 1.65rem;
  font-family: ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.75rem;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: flex;
  position: relative;
  transform: rotate(-2deg);
}

.button-53:focus {
  outline: 0;
}

.button-53:after {
  content: "";
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  border: 1px solid #000;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

.button-53:hover:after {
  bottom: 2px;
  left: 2px;
}

@media (min-width: 768px) {
  .button-53 {
    padding: .75rem 3rem;
    font-size: 1.25rem;
  }
}

.padding30 {
  padding-left: 30px;
  padding-right: 30px;
}

.checkbox-wrapper-5 .check {
  --size: 40px;
  perspective: 400px;
  line-height: 0;
  font-size: var(--size);
  background: linear-gradient(90deg, #add8e6, #add8e6);
  position: relative;
}

.checkbox-wrapper-5 .check input[type="checkbox"], .checkbox-wrapper-5 .check label, .checkbox-wrapper-5 .check label:before, .checkbox-wrapper-5 .check label:after, .checkbox-wrapper-5 .check {
  -webkit-appearance: none;
  appearance: none;
  border-radius: var(--size);
  box-sizing: border-box;
  cursor: pointer;
  border: 0;
  transition: all .35s ease-in-out;
  display: inline-block;
}

.checkbox-wrapper-5 .check label {
  width: calc(2.2 * var(--size));
  height: var(--size);
  background: #d7d7d7;
  overflow: hidden;
}

.checkbox-wrapper-5 .check input[type="checkbox"] {
  z-index: 1;
  width: calc(.8 * var(--size));
  height: calc(.8 * var(--size));
  top: calc(.1 * var(--size));
  left: calc(.1 * var(--size));
  background: linear-gradient(45deg, #dedede, #fff);
  outline: none;
  margin: 0;
  position: absolute;
  box-shadow: 0 6px 7px #0000004d;
}

.checkbox-wrapper-5 .check input[type="checkbox"]:checked {
  left: calc(1.3 * var(--size));
}

.checkbox-wrapper-5 .check input[type="checkbox"]:checked + label {
  background: none;
}

.checkbox-wrapper-5 .check label:before, .checkbox-wrapper-5 .check label:after {
  content: "· ·";
  left: calc(.15 * var(--size));
  top: calc(.5 * var(--size));
  height: var(--size);
  letter-spacing: calc(-.04 * var(--size));
  color: #9b9b9b;
  z-index: 2;
  font-family: Times New Roman, serif;
  font-size: calc(.6 * var(--size));
  transform-origin: 0 0 calc(-.5 * var(--size));
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 0;
  position: absolute;
  overflow: hidden;
}

.checkbox-wrapper-5 .check label:after {
  content: "●";
  top: calc(.65 * var(--size));
  left: calc(.2 * var(--size));
  height: calc(.1 * var(--size));
  width: calc(.35 * var(--size));
  font-size: calc(.2 * var(--size));
  transform-origin: 0 0 calc(-.4 * var(--size));
}

.checkbox-wrapper-5 .check input[type="checkbox"]:checked + label:before, .checkbox-wrapper-5 .check input[type="checkbox"]:checked + label:after {
  left: calc(1.55 * var(--size));
  top: calc(.4 * var(--size));
  line-height: calc(.1 * var(--size));
  transform: rotateY(360deg);
}

.checkbox-wrapper-5 .check input[type="checkbox"]:checked + label:after {
  height: calc(.16 * var(--size));
  top: calc(.55 * var(--size));
  left: calc(1.6 * var(--size));
  font-size: calc(.6 * var(--size));
  line-height: 0;
}

#speculationWarning {
  color: #000;
  padding-left: 10px;
  font-family: sans-serif;
  font-size: small;
  animation: 1.2s infinite blinkingText;
  display: none;
}

@keyframes blinkingText {
  0% {
    color: #000;
  }

  49% {
    color: #000;
  }

  60% {
    color: #0000;
  }

  99% {
    color: #0000;
  }

  100% {
    color: #000;
  }
}

.button-50 {
  -webkit-appearance: button;
  appearance: button;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #000;
  background-image: none;
  border: 1px solid #000;
  border-radius: 4px;
  margin: 0 5px 10px 0;
  padding: 12px 40px;
  font-family: matrix-code;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: inline-block;
  overflow: visible;
  box-shadow: 4px 4px #fff, 4px 4px 0 1px #000;
}

.button-50:focus, .button-50:hover {
  text-decoration: none;
}

.button-50:active {
  outline: 0;
  box-shadow: inset 0 3px 5px #00000020;
}

.button-50:not([disabled]):active {
  transform: translate(2px, 2px);
  box-shadow: 2px 2px #fff, 2px 2px 0 1px #000;
}

@media (min-width: 768px) {
  .button-50 {
    padding: 12px 50px;
  }
}

.button-2 {
  color: #333;
  cursor: pointer;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  background-color: #3333330d;
  border-width: 0;
  border-radius: 8px;
  margin: 0;
  padding: 10px 12px;
  font-family: Haas Grot Text R Web, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  transition: all .2s;
  display: inline-block;
}

.button-57 {
  color: #18181a;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  background: #fff;
  border: 1px solid #18181a;
  padding: 18px 18px 17px;
  font-size: 15px;
  line-height: 15px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.button-57 span:first-child {
  z-index: 10;
  transition: color .6s cubic-bezier(.48, 0, .12, 1);
  position: relative;
}

.button-57 span:last-child {
  color: #fff;
  z-index: 100;
  opacity: 0;
  height: 14px;
  line-height: 13px;
  transition: all .5s cubic-bezier(.48, 0, .12, 1);
  display: block;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 50%;
  transform: translateY(225%)translateX(-50%);
}

.button-57:after {
  content: "";
  width: 100%;
  height: 100%;
  transform-origin: bottom;
  z-index: 50;
  background-color: #000;
  transition: transform .6s cubic-bezier(.48, 0, .12, 1);
  position: absolute;
  bottom: -50%;
  left: 0;
  transform: skewY(9.3deg)scaleY(0);
}

.button-57:hover:after {
  transform-origin: bottom;
  transform: skewY(9.3deg)scaleY(2);
}

.button-57:hover span:last-child {
  opacity: 1;
  transition: all .9s cubic-bezier(.48, 0, .12, 1);
  transform: translateX(-50%)translateY(-100%);
}

#ticketsLeft {
  font-family: sans-serif;
  font-size: 30px;
}

#priceInfo {
  color: gray;
  margin-top: 5px;
  font-size: 13px;
}

#eventName {
  margin-bottom: 10px;
  font-size: 20px;
}

.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}

.lds-circle > div {
  width: 64px;
  height: 64px;
  background: #bbdcf7;
  border-radius: 50%;
  margin: 8px;
  animation: 2.4s cubic-bezier(0, .2, .8, 1) infinite lds-circle;
  display: inline-block;
}

/*# sourceMappingURL=index.89c23865.css.map */
